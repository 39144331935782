import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FCWithChildren } from '../../types/FCWithChildren';

const PicklistEditorPortal: FCWithChildren = ({ children }) => {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(function loadContainer() {
    setContainer(document.getElementById('picklist-detail-button-portal'));
  }, []);

  return container && createPortal(children, container);
};

export default PicklistEditorPortal;
